body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  width: 100%;
  height: 100vh;
  background: url(./images/hero-bg.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
  z-index: 10;
}

.sticky {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
}

.bg-sec {
  background: url("./images/india-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.heading-sty {
  color: #505bc7;
  text-align: left;
  font-size: 4rem;
  margin-bottom: 2rem;
}

.para-sty {
  font-size: 1.5rem;
  text-align: left;
  line-height: 2.5rem;
}

.about-style {
  text-align: left;
  font-size: 1.5rem;
  line-height: 3.5rem;
}

.pdin-y {
  padding: 1rem 0rem;
}

.bg-img-all {
  display: flex;
  justify-content: end;
  align-items: start;
  margin-top: 8rem;
}

.bg-img-all img {
  width: 90%;
}

.pp-b {
  padding-bottom: 3rem;
}

.socialSec {
  display: flex;
}

.socialSec img {
  width: 100%;
}

.sw {
  width: 5%;
}

.logo-img {
  width: 20%;
}

a.text-light.nav-link {
  font-size: 1.5rem;
  margin: 0rem 1rem;
}

a.text-light.nav-link:hover {
  color: #eb8720 !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgb(34 39 89) !important;
}

@media screen and (max-width: 1200px) {
  .logo-img {
    width: 60px;
  }

  .header {
    width: 100%;
    height: 30rem;
    margin-top: 80px;
  }

  button.navbar-toggler.collapsed {
    background: white;
  }
  .heading-sty {
    font-size: 2rem;
  }

  .para-sty {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .bg-img-all {
    justify-content: center;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }

  .bg-img-all img {
    width: 100%;
  }
}

@media screen and (max-width: 990px) {
  .ms-auto.my-2.my-lg-0.text-light.navbar-nav.navbar-nav-scroll {
    max-height: 100vh !important;
    height: 100vh;
  }
}
